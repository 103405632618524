import React from "react"
import HTMLContent from "../components/HTMLContent/HTMLContent"
import { graphql } from "gatsby"
import BodyText from "../components/BodyText/BodyText"
import Container from "../components/Container/Container"
import PropTypes from "prop-types"

export const AboutPageTemplate = ({ title, content }) => {
  return (
    <Container>
      <h1>{title}</h1>
      <BodyText>
        <HTMLContent content={content} />
      </BodyText>
    </Container>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <AboutPageTemplate title={page.frontmatter.title} content={page.html} />
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const query = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
